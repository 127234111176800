import { Outlet } from 'react-router-dom';

const Resources = () => {
  return (
    <div>
      Resouces
      <Outlet />
    </div>
  );
};

export default Resources;
